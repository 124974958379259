<template>
  <div >
    <div style="padding-top: 60px; padding-bottom: 50px;" >
      <router-view></router-view>
    </div>
    <Header style="top:0; position: fixed; width: 100%;" />
    <!-- <Footer style="bottom: 0; position: fixed; width: 100%" /> -->
  </div>
</template>

<script>
import Header from "@/components/TenentHeader";
// import Footer from "@/components/TenentFooter";
export default {
  name: "Main",
  components: {
    Header,
    // Footer,
  },
};
</script>