<template>
  <div
    class="p0 bg-dark"
    style="top: 0px; position: fixed; width: 100%; height: 55px; padding: 10px">
    <div class="row upper-form">
      <div class="col">
        <nav
          class="navbar navbar-expand-lg navbar-light dashboard-menu d-none d-xl-block">
          <div class="navbar-collapse mt-2" id="navbarNav">
            <ul
              class="navbar-nav"
              style="width: 70%; margin-top: -15px; display: block ruby">
              <li style="float: left">
                <img src="/img/logo_new.png" height="50" />
              </li>
              <li
                style="float: left"
                class="nav-item active 618135"
                v-if="role == 'SUPER_ADMIN'">
                <a
                  style="margin-top: 10px; padding: 8px 4px"
                 v-on:click="redirectTo('/tenents')"
                  class="nav-link"
                  >TENANTS
                </a>
              </li>
              <li
                style="float: left"
                class="nav-item active 618135"
                v-if="role == 'SUPER_ADMIN'">
                <a
                  style="margin-top: 10px; padding: 8px 4px"
                 v-on:click="redirectTo('/manage-scripts')"
                  class="nav-link"
                  >MANAGE SCRIPTS
                </a>
              </li>
              <li
                style="float: left"
                class="nav-item active 618135"
                v-if="role == 'SUPER_ADMIN'">
                <a
                  style="margin-top: 10px; padding: 8px 4px"
                 v-on:click="redirectTo('/manage-feed')"
                  class="nav-link"
                  >MANAGE FEED
                </a>
              </li>
            </ul>
            <marquee
              id="appMarquee"
              scrollamount="4"
              style="
                width: 30%;
                font-size: 14px;
                color: red;
                margin-top: -22px;
                font-weight: 600;
              ">
              {{ message }}
            </marquee>
          </div>
        </nav>
        <button
          class="navbar-toggler d-xl-none"
          type="button"
          v-on:click="toggleMenu()"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            style="fill: rgba(0, 0, 0, 1); transform: ; msfilter: ">
            <path d="M4 6h16v2H4zm0 5h16v2H4zm0 5h16v2H4z"></path>
          </svg>
        </button>

        <div
          class="sidebar-wrapper"
          style="overflow-y: scroll"
          id="sidebar-div">
          <ul class="metismenu" id="menu">
            <li class="nav-item" v-if="role == 'SUPER_ADMIN'">
              <a v-on:click="redirectTo('/tenents')" class="nav-link"
                >TENANTS
              </a>
            </li>
            <li class="nav-item" v-if="role == 'SUPER_ADMIN'">
              <a v-on:click="redirectTo('/manage-scripts')" class="nav-link"
                >MANAGE SCRIPTS
              </a>
            </li>
             <li class="nav-item" v-if="role == 'SUPER_ADMIN'">
              <a  v-on:click="redirectTo('/manage-feed')" class="nav-link"
                >MANAGE FEED
              </a>
            </li>
          </ul>
        </div>
      </div>

      <header class="top-header" style="display: contents">
        <nav class="navbar navbar-expand">
          <div class="left-topbar d-flex align-items-center">
            <a class="toggle-btn">
              <i class="bx bx-menu"></i>
            </a>
          </div>
          <div class="right-topbar ml-auto">
            <ul class="navbar-nav">
              <li class="nav-item dropdown dropdown-user-profile">
                <a
                  class="nav-link dropdown-toggle-nocaret pr-0"
                  data-toggle="dropdown">
                  <div class="media user-box align-items-center mr-3">
                    <!-- <div
                      class="mr-4"
                      style="margin-top: -9px"
                      v-if="details.is_notification == 1">
                      <i
                        class="fa fa-volume-up"
                        v-on:click="updatepermission()"
                        aria-hidden="true"
                        style="color: black; font-size: 20px"></i>
                    </div>
                    <div
                      class="mr-4"
                      style="margin-top: -10px"
                      v-if="details.is_notification == 0">
                      <i
                        class="fa fa-volume-off"
                        v-on:click="updatepermission()"
                        aria-hidden="true"
                        style="color: black; font-size: 20px"></i>
                    </div> -->
                    <div
                      class="mr-4 position-relative"
                      style="margin-top: -10px"
                      v-if="role == 'USER'">
                      <i
                        v-on:click="showNotificationDropdown()"
                        class="fa fa-bell"
                        style="color: black; font-size: 20px"
                        aria-hidden="true"></i>
                      <span class="badge badge-danger count">{{
                        details.count
                      }}</span>
                    </div>

                    <i
                      v-on:click="showDropdown()"
                      class="fa fa-user fa-2x"
                      style="color: black; margin-top: -13px; font-size: 24px"
                      aria-hidden="true"></i>
                  </div>
                </a>
                <div
                  v-click-outside="clickedOutsideV2"
                  id="notification-dropdown-id"
                  class="dropdown-menu dropdown-menu-end show"
                  style="
                    position: absolute;
                    inset: 0px 0px auto auto;
                    margin: 0px;
                    transform: translate3d(0px, 49px, 0px);
                    margin-right: 1px;
                    height: 300px;
                    overflow-y: scroll;
                  "
                  data-popper-placement="bottom-end">
                  <h5
                    class="text-center"
                    style="margin:0px;!important;font-weight:bold;">
                    Notifications
                  </h5>
                  <div class="dropdown-divider"></div>
                  <li v-for="(data, index) in notificationList" :key="index">
                    <div class="dropdown-item d-flex">
                      <div class="font-12 time col-10">
                        <h6 style="margin:0px;!important;font-size:15px">
                          {{
                            data.description
                              ? $helperService.getUpperCase(data.description)
                              : ""
                          }}
                        </h6>
                        <span style="margin:0px;!important;font-size:10px">
                          {{
                            data.created_at
                              ? $helperService.getCustomDateYearnew2(
                                  data.created_at
                                )
                              : ""
                          }}
                        </span>
                      </div>
                      <div>
                        <span
                          v-if="!data.is_read"
                          class="badge badge-danger rounded-pill px-2 float-right"
                          style="
                            color: white;
                            justify-content: end;
                            display: flex;
                          "
                          >Unread</span
                        >
                        <span
                          v-else
                          class="badge badge-success rounded-pill px-2 float-right"
                          style="
                            color: white;
                            justify-content: end;
                            display: flex;
                          "
                          >Seen</span
                        >
                      </div>
                    </div>
                    <div class="dropdown-divider"></div>
                  </li>
                  <li
                    v-if="notificationList.length == 0"
                    style="
                      width: 300px;
                      height: 100px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    ">
                    <img v-if="isloader" src="/img/loader.gif" />
                    <span
                      style="color: black !important"
                      v-if="notificationList.length == 0 && !isloader"
                      >No Data Found</span
                    >
                  </li>
                </div>
                <div
                  v-click-outside="clickedOutside"
                  id="dropdown-id"
                  class="dropdown-menu dropdown-menu-end show"
                  style="
                    position: absolute;
                    inset: 0px 0px auto auto;
                    margin: 0px 13px 0px 0px;
                    transform: translate3d(0px, 49px, 0px);
                  "
                  data-popper-placement="bottom-end">
                  <h6
                    class="dropdown-header ng-binding"
                    style="font-size: 15px; font-weight: bold; color: #a77500">
                    Welcome {{ $helperService.getTitleCase(details.name) }}
                    {{ details.code ? "(" + details.code + ")" : "" }}
                    <p>
                      ({{ role.replace("_", " ") }}
                      {{
                        details.server_code ? " - " + details.server_code : ""
                      }})
                    </p>
                  </h6>

                  <div class="dropdown-divider"></div>

                  <a class="dropdown-item cursor-pointer">
                    <i class="fa fa-user mr-2" aria-hidden="true"></i>

                    <span class="align-middle">My Profile</span>
                  </a>
                  <a
                    class="dropdown-item cursor-pointer"
                    v-on:click="showChangePasswordPopup()">
                    <i class="fa fa-key mr-2" aria-hidden="true"></i>

                    <span class="align-middle">Change Password</span>
                  </a>
                  <div
                    class="dropdown-divider"
                    v-if="getSavedLogin.length > 0"></div>
                  <a
                    class="dropdown-item cursor-pointer align-middle"
                    v-if="getSavedLogin.length > 0">
                    <i class="fa fa-key mr-2" aria-hidden="true"></i>

                    <span class="align-middle">Saved Logins : </span>
                  </a>
                  <div
                    v-for="(data, index) in getSavedLogin"
                    :key="index"
                    class="">
                    <div
                      class="col-12 row cursor-pointer mt-1 p-0 m-0"
                      style="
                        background-color: #e3e3ea;
                        border-radius: 4px 4px 4px 4px;
                        height: 40px;
                      ">
                      <div
                        class="col-8"
                        style="
                          justify-content: start;
                          display: inline-flex;
                          margin-top: 14px;
                          font-size: 12px;
                        ">
                        <i
                          class="fa fa-user mr-2"
                          style="margin-top: 3px !important"
                          aria-hidden="true"></i>
                        {{ data.server_code }} - {{ data.code }}
                      </div>
                      <div
                        class="col-4"
                        style="
                          justify-content: end;
                          display: inline-flex;
                          margin-top: 14px;
                        ">
                        <i
                          class="fa fa-sign-in ml-3"
                          title="login"
                          aria-hidden="true"
                          v-on:click="login(index)"></i>
                        <i
                          class="fa fa-trash-o ml-3"
                          aria-hidden="true"
                          style="color: red"
                          title="Remove"
                          v-on:click="remove(index)"></i>
                      </div>
                    </div>
                  </div>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item cursor-pointer" v-on:click="logout()"
                    ><i class="fa fa-sign-out mr-2" aria-hidden="true"></i>

                    <span class="align-middle">Logout</span></a
                  >
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </div>
    <TextModal ref="textModal">
      <div class="modal-header pt-0">
        <h5 class="modal-title">Change Password</h5>
        <button
          type="button"
          class="close"
          v-on:click="$refs.textModal.closeModal">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <Form ref="changePasswordForm" @submit="changePassword()">
        <div class="col-lg-12 col-sm-12">
          <div class="form-group">
            <label>Old Password</label>
            <span class="text-danger">*</span>
            <Field
              type="password"
              name="_password"
              class="form-control"
              placeholder="Old Password"
              v-model="old_password"
              rules="required:old password"
              :validateOnInput="true" />
            <ErrorMessage name="_password" class="text-danger" />
          </div>
        </div>
        <div class="col-lg-12 col-sm-12">
          <div class="form-group">
            <label>New Password</label>
            <span class="text-danger">*</span>
            <Field
              type="password"
              name="name_password"
              class="form-control"
              placeholder="New Password"
              v-model="new_password"
              rules="required:new password|minLength:6"
              :validateOnInput="true" />
            <ErrorMessage name="name_password" class="text-danger" />
          </div>
        </div>
        <div class="col-lg-12 col-sm-12">
          <div class="form-group">
            <label>Confirm Password</label>
            <span class="text-danger">*</span>
            <Field
              type="password"
              placeholder="Confirm Password"
              name="name_ccpassword"
              class="form-control"
              rules="required:confirm password|confirmed:@name_password"
              :validateOnInput="true" />
            <ErrorMessage name="name_ccpassword" class="text-danger" />
          </div>
        </div>
        <div class="pb-0 text-center">
          <button class="save-btn" id="change-password-button">Save</button>
        </div>
      </Form>
    </TextModal>
    <ErrorComponent @retry="getLoginUserDetail" ref="errorComponent" />
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import TextModal from "@/components/TenentTextModal.vue";
import swal from "sweetalert2";
import ErrorComponent from "@/components/TenentErrorComponent";

export default {
  name: "Menu",
  components: {
    TextModal,
    Form,
    Field,
    ErrorMessage,
    ErrorComponent,
  },
  data() {
    return {
      pageName: "",
      role: localStorage.getItem("role"),
      view: {
        is_system_user_show: false,
        is_system_show: false,
        is_system: false,
        is_system_report: false,
      },
      message: "",
      password: "",
      confirm_password: "",
      details: {
        count: 0,
      },
      notificationList: [],
      isShow: false,
      isPlayAudio: false,
      time: 0,
      eventDisable: false,
      NotieventDisable: false,
      keyword: "",
      isCall: false,
      client: undefined,
      count: 0,
      text: "",
      isloader: false,
      getSavedLogin: [],
    };
  },
  watch: {
    "$route.fullPath"(newValue) {
      var pathname = newValue.replace("/", "");
      this.pageName = pathname;
    },
  },
  mounted() {
    this.getAccess();
    this.getLoginUsers();
    this.pageName = this.$route.fullPath.replace("/", "");
    this.getLoginUserDetail();
    this.clickedOutside();
    this.clickedOutsideV2();
    this.getDetails();
    this.$helperService.showMenu("false");
  },
  methods: {
    getLoginUsers() {
      this.getSavedLogin = JSON.parse(
        localStorage.getItem("saved_logins") || "[]"
      );
      for (var i in this.getSavedLogin) {
        if (localStorage.getItem("code") == this.getSavedLogin[i].code) {
          this.getSavedLogin.splice(i, 1);
        }
      }
    },
    remove(index) {
      for (var i in this.getSavedLogin) {
        if (i == index) {
          this.getSavedLogin.splice(i, 1);
        }
      }
      localStorage.setItem("saved_logins", JSON.stringify(this.getSavedLogin));
    },
    login(index) {
      this.$refs.errorComponent.updateFormLoader(true);
      for (var i in this.getSavedLogin) {
        if (i == index) {
          var obj = this.getSavedLogin[i];
        }
      }
      this.$api
        .postAPI({
          _action: "/ouath-token",
          _body: obj,
        })
        .then((res) => {
          if (res && res.access_token) {
            localStorage.setItem("token", res.access_token);
            this.getLoginUserDetail2();
          }
        })
        .catch(() => {});
    },
    getLoginUserDetail2() {
      this.$api
        .getAPI({
          _action: "/me",
        })
        .then((res) => {
          if (res) {
            localStorage.setItem("role", res.role);
            localStorage.setItem("userid", res.id);
            localStorage.setItem("code", res.code);
            localStorage.setItem("client_ids", JSON.stringify(res.client_ids));
            if (res.role == "ADMIN") {
              this.$router.push("/super-masters");
            } else if (res.role == "SUPER_ADMIN") {
              this.$router.push("/tenents");
            } else {
              localStorage.setItem("is_edit", res.is_edit);
              localStorage.setItem(
                "is_add_trade_permission",
                res.is_add_trade_permission
              );
              this.$router.push("/dashboard");
            }
            this.$refs.errorComponent.updateFormLoader(false);
            window.location.reload();
          }
        })
        .catch(() => {
          localStorage.removeItem("token");
          localStorage.removeItem("role");
          localStorage.removeItem("client_ids");
        });
    },
    toggleMenu() {
      if (document.getElementById("sidebar-div").style.width == "300px") {
        this.$helperService.showMenu("false");
      } else {
        this.$helperService.showMenu("true");
      }
    },
    redirectTo(url) {
      this.$helperService.showMenu("false");
      this.$router.push(url);
    },
    showDown(type) {
      if (type == "is_system_user_show") {
        this.view = {
          is_system_user_show: !this.view.is_system_user_show,
          is_system_show: false,
          is_system: false,
          is_system_report: false,
        };
      } else if (type == "is_system") {
        this.view = {
          is_system: !this.view.is_system,
          is_system_user_show: false,
          is_system_show: false,
          is_system_report: false,
        };
      } else if (type == "is_system_show") {
        this.view = {
          is_system_show: !this.view.is_system_show,
          is_system_user_show: false,
          is_system: false,
          is_system_report: false,
        };
      } else if (type == "is_system_report") {
        this.view = {
          is_system_report: !this.view.is_system_report,
          is_system_user_show: false,
          is_system: false,
          is_system_show: false,
        };
      }
    },

    updatepermission() {
      var text = "";
      if (this.details.is_notification == 1) {
        text = "Are you sure you want to slient trade execute sound";
      }
      if (this.details.is_notification == 0) {
        text = "Are you sure you want to on trade execute sound";
      }
      swal
        .fire({
          title: "Are you sure?",
          text: text,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          customClass: {
            confirmButton: "btn btn-blue btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.$api
              .putAPI({
                _action: "/update-edit-notification",
              })
              .then((res) => {
                if (res) {
                  // this.$notify({
                  //   title: "Success",
                  //   type: "success",
                  //   text: res.message,
                  // });
                  this.getLoginUserDetail();
                }
              })
              .catch(() => {});
          } else {
            this.getLoginUserDetail(0);
          }
        });
    },
    getNotificationList() {
      this.isloader = true;
      this.$api
        .getAPI({
          _action: "/message-list",
        })
        .then((res) => {
          this.notificationList = res;
          this.isloader = false;
        })
        .catch(() => {
          this.isloader = false;
        });
    },
    getAccess() {
      this.$api
        .getAPI({
          _action: "/user-access",
        })
        .then((res) => {
          if (res) {
            var token = localStorage.getItem("temp_token");
            if (token) {
              var access = {
                is_summary: 1,
                is_home: 1,
                is_market_watch: 1,
                is_user: 1,
                is_orderEdit: 1,
                is_orderDelete: 1,
                is_orderExecute: 1,
                is_orderExport: 1,
                is_tradeAdd: 1,
                is_tradeEdit: 1,
                is_tradeDelete: 1,
                is_tradeExport: 1,
                is_tradeReverse: 1,
                is_rollover: 1,
                is_closePosition: 1,
                is_positionBook: 1,
                is_PositionExport: 1,
                is_summaryReport: 1,
                is_branchReport: 1,
                is_ledgerReport: 1,
                is_settlement: 1,
                is_accountEditLog: 1,
                is_orderEditLog: 1,
                is_quantitySetting: 1,
                is_orderLimit: 1,
                is_segmentSetting: 1,
                is_blockScript: 1,
                is_blockIp: 1,
                is_transferSetting: 1,
                is_manager: 1,
                is_master: 1,
                is_broker: 1,
                is_customer: 1,
                is_addAccount: 1,
                is_checkboxEdit: 1,
                is_ledgerEdit: 1,
              };
              this.$store.commit("setAccess", access);
              localStorage.setItem("access", JSON.stringify(access));
            } else {
              this.$store.commit("setAccess", res.access);
              localStorage.setItem("access", JSON.stringify(res.access));
            }
          }
        })
        .catch(() => {});
    },
    getDetails() {
      this.$api
        .getAPI({
          _action: "/settings",
        })
        .then((res) => {
          this.message = res.header_message;
        })
        .catch(() => {});
    },
    clickedOutside() {
      if (!this.eventDisable) {
        var dropdownId = document.getElementById("dropdown-id");
        if (dropdownId) {
          dropdownId.classList.remove("show");
        }
      } else {
        this.eventDisable = false;
      }
    },
    clickedOutsideV2() {
      if (!this.NotieventDisable) {
        var dropdownId = document.getElementById("notification-dropdown-id");
        if (dropdownId) {
          dropdownId.classList.remove("show");
        }
      } else {
        this.NotieventDisable = false;
      }
    },
    showDropdown() {
      this.eventDisable = true;
      var dropdownId = document.getElementById("dropdown-id");
      if (dropdownId) {
        if (dropdownId.className.includes("show")) {
          dropdownId.classList.remove("show");
        } else {
          dropdownId.classList.add("show");
        }
      }
    },
    showNotificationDropdown() {
      this.getNotificationList();
      this.getLoginUserDetail();
      this.NotieventDisable = true;
      var dropdownId = document.getElementById("notification-dropdown-id");
      if (dropdownId) {
        if (dropdownId.className.includes("show")) {
          dropdownId.classList.remove("show");
        } else {
          dropdownId.classList.add("show");
        }
      }
    },
    logout() {
      swal
        .fire({
          title: "Are you sure?",
          text: "You will be logged out of the portal",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Logout",
          customClass: {
            confirmButton: "btn btn-blue btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            // localStorage.clear();
            localStorage.removeItem("token");
            localStorage.removeItem("role");
            localStorage.removeItem("client_ids");
            this.$router.push("/login");
          }
        });
    },
    getLoginUserDetail() {
      this.$api
        .getAPI({
          _action: "/me",
        })
        .then((res) => {
          this.details = res;
          this.$store.commit("setUser", res);
          localStorage.setItem("role", res.role);
          localStorage.setItem("is_edit", res.is_edit);
          localStorage.setItem(
            "is_add_trade_permission",
            res.is_add_trade_permission
          );
        })
        .catch(() => {});
    },
    showChangePasswordPopup() {
      this.clickedOutside();
      this.clickedOutsideV2();
      this.$refs.textModal.showModal();
      this.$refs.changePasswordForm.resetForm();
    },
    changePassword() {
      var obj = {};
      obj.old_password = this.old_password;
      obj.new_password = this.new_password;
      obj.id = this.details.id;
      this.$api
        .putAPI({
          _action: "/reset/password",
          _body: obj,
          _buttonId: "change-password-button",
        })
        .then((res) => {
          this.$refs.textModal.closeModal();
          this.$notify({
            title: "Success",
            type: "success",
            text: res.message,
          });
        })
        .catch(() => {});
    },
  },
};
</script>
