import { createWebHistory, createRouter } from "vue-router";
import Login from "@/views/Onboarding/tenent-login.vue";
import Terms from "@/views/CMS/tenent-termsAndConditions.vue";
import Tenants from "@/views/Users/tenent-tenants.vue";
import AccessDenied from "@/views/Access/tenent-access-denied.vue";
import MainView from "@/views/main.vue";
import ManageScripts from "@/views/Settings/tenent-manage-script.vue";
import ManageFeed from "@/views/Settings/tenent-manage-feed.vue";

// THIS IS TENENT INDEX.JS NOT MARKET FRONTEND INDEX.JS
const routes = [
  {
    path: "",
    redirect: Login,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/login/:type",
    name: "AdminLogin",
    component: Login,
  },
  {
    path: "/terms",
    name: "Terms",
    component: Terms,
  },
  {
    path: "",
    name: "Main",
    component: MainView,
    meta: {
      requiresAuth: false,
    },
    children: [

      {
        path: "/manage-scripts",
        name: "ManageScripts",
        component: ManageScripts,
      },
      {
        path: "/manage-feed",
        name: "ManageFeed",
        component: ManageFeed,
      },

      {
        path: "/tenents",
        name: "Tenants",
        component: Tenants,
      },
      {
        path: "/access-denied",
        name: "access-denied",
        component: AccessDenied,
      },

    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("token") == null) {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    if (to.path === "/login") {
      if (localStorage.getItem("token") != null) {
        next({
          path: "/dashboard",
          params: { nextUrl: to.fullPath },
        });
      } else {
        next();
      }
    } else {
      next();
    }
  }

});



export default router;
