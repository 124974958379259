import { createStore } from "vuex";
const store = createStore({
  state() {
    return {
      balance: 0,
      bider: {},
      user: {},
      auctions: [],
      access: {},
      warningMessage: "",
      congratulationMessage: "",
      sorryMessage: "",
      alertMessage: "",
      maintenance: "",
      maintenanceImage: "",
    };
  },
  mutations: {
    setBalance(state, payload) {
      state.balance = payload;
    },
    setBidder(state, payload) {
      state.bider = payload;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    setAccess(state, payload) {
      state.access = payload;
    },
    setAuctions(state, payload) {
      state.auctions = payload;
    },
    setWarningMessage(state, payload) {
      state.warningMessage = payload;
    },
    setCongratulationMessage(state, payload) {
      state.congratulationMessage = payload;
    },
    setSorryMessage(state, payload) {
      state.sorryMessage = payload;
    },
    setAlertMessage(state, payload) {
      state.alertMessage = payload;
    },
    setMaintenance(state, payload) {
      state.maintenance = payload;
    },
    setMaintenanceImage(state, payload) {
      state.maintenanceImage = payload;
    },
  },
  getters: {
    getBalance(state) {
      return state.balance;
    },
    getUser(state) {
      return state.user;
    },
    getAccess(state) {
      return state.access;
    },
    getBidder(state) {
      return state.bider;
    },
    getAuctions(state) {
      return state.auctions;
    },
    getCongratulationMessage(state) {
      return state.congratulationMessage;
    },
    getWarningMessage(state) {
      return state.warningMessage;
    },
    getSorryMessage(state) {
      return state.sorryMessage;
    },
    getAlertMessage(state) {
      return state.alertMessage;
    },
    getMaintenance(state) {
      return state.maintenance;
    },
    getMaintenanceImage(state) {
      return state.maintenanceImage;
    },
  },
  actions: {},
});

export default store;
