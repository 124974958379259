<template>
  <div>
    <div class="container form-signin3">
      <div class="row">
        <div class="col-md-12" style="padding-left: 50px; padding-right: 50px">
          <h2
            class="text-start"
            style="color: rgb(29, 68, 91); font-weight: 600">
            Manage Feed
          </h2>
          <form class="form-inline transparent-form p10 border-page m-t-2">
            <div class="col-lg-12 col-sm-12 row mt-3">
              <div
                class="row ml-2 p-3"
                style="
                  font-size: 12px;
                  font-weight: 550 !important;
                  margin-top: -18px;
                ">
                <input
                  id="is_broker_gross"
                  name="is_broker_gross"
                  type="checkbox"
                  v-model="detail.is_publish"
                  class="ml-1"
                  :true-value="1"
                  :false-value="0" />
                <label class="col-form-label" style="display: flex"
                  ><span class="ml-2">Publish </span></label
                >
              </div>
              <div class="col-lg-2 input-group mb-2 mr-sm-2 ml-3">
                <Field
                  type="date"
                  class="form-control"
                  name="trade_date"
                  style="
                    width: 150px;
                    height: 30px !important;
                    padding: 0px !important;
                    font-size: 12px;
                    word-spacing: 0px;
                    letter-spacing: 3px;
                  "
                  data-date-format="DD-MM-YYYY"
                  v-model="detail.custom_expiry"
                  placeholder="Trade Date" />
              </div>
              <div class="col-lg-1 input-group mb-2 mr-sm-2 ml-3">
                <button
                  id="btn-search"
                  type="button"
                  style="width: 100px"
                  v-on:click="updatescripts()"
                  class="btn btn-primary mb-2">
                  Add
                </button>
              </div>
              <div class="col-lg-1 input-group mb-2 mr-sm-2">
                <button
                  id="btn-search"
                  type="button"
                  style="width: 120px"
                  v-on:click="restartFeed()"
                  class="btn btn-warning mb-2">
                  Restart Feed
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Field } from "vee-validate";
export default {
  components: {
    Field,
  },
  name: "FEED",
  data() {
    return {
      detail: {},
    };
  },
  mounted() {},
  methods: {
    restartFeed() {
      this.$api
        .getAPI({
          _action: "/feed-restart",
          _isExternal: true,
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
        })
        .catch(() => {});
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect {
  background: white !important;
  max-height: 30px !important;
  min-height: 0px !important;
}

.multiselect-tag {
  background: #9cc338 !important;
}

.option-icon {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  margin-right: 10px;
}
</style>
